import React, { useState } from 'react'
import { Link, graphql } from 'gatsby'
import tw from 'twin.macro'
import { useForm } from 'react-hook-form'

// Components
import Layout from '../../components/LayoutHospitality'
import {
  CopyLGStyle,
  CopyStyle,
  HeaderLGStyle,
  HeaderStyle,
  SubheadSMStyle,
  SubheadStyle,
} from '../../components/UIHospitality'
import FrequentlyAskedQuestions from '../../components/FrequentlyAskedQuestions'
import CallToAction from '../../components/CallToAction'
import Testimonials from '../../components/Testimonials'
import Features from '../../components/Features'

// Assets
import appStore from '../../images/app-store.svg'
import googlePlay from '../../images/google-play.svg'
import { GatsbyImage } from 'gatsby-plugin-image'
import Seo from '../../components/Seo'

const App = ({ location, data }) => {
  const pageData = data.contentfulAppPage
  const globalData = data.contentfulGlobalSettings

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm()
  const [formSuccess, setFormSuccess] = useState(null)

  const onSubmit = (data) => {
    const headers = new Headers({
      'Content-Type': 'application/x-www-form-urlencoded',
    })
    const options = {
      method: 'POST',
      headers: headers,
      body: new URLSearchParams({
        number: data.phone,
      }),
    }
    fetch('https://api.staffy.com/api/sms/send', options)
      .then((response) => response.json())
      .then((response) => {
        if (response.sucess) setFormSuccess(true)
      })
  }

  return (
    <Layout location={location}>
      <Seo
        title={pageData.metadataTitle || 'Send App'}
        description={pageData.metadataDescription}
        image={
          pageData.metadataImage
            ? `https:${pageData.metadataImage.file.url}`
            : undefined
        }
      />
      {/* Hero */}
      <section tw="bg-[#202C3A] text-[#F1FAFD]">
        <div tw="flex flex-col lg:(grid grid-cols-[1fr repeat(12, calc(1024px/12)) 1fr] relative h-[620px])">
          {/* Image */}
          <div tw="lg:(col-span-6 relative h-full)">
            <GatsbyImage
              tw="w-full lg:(absolute w-full h-full)"
              image={pageData.heroImage.gatsbyImageData}
              alt={pageData.heroImage.title}
            />
            <div tw="lg:(absolute w-36 h-full bg-gradient-to-l from-[#202C3A] to-transparent top-0 right-[-1px])" />
          </div>
          {/* Content */}
          <div tw="mx-7 mb-9 py-7 lg:(mx-0 col-start-8 col-span-6 py-0 mb-24) flex flex-col justify-end">
            {/* Headers */}
            <h1 css={[SubheadSMStyle, tw`mb-7 lg:(mb-4)`]}>Staffy App</h1>
            <h2 css={[HeaderLGStyle, tw`mb-12 text-[#C2E1EA] lg:(mb-9)`]}>
              Take control of where and when you want to work.
            </h2>
            <div css={[CopyStyle, tw`mb-4`]}>Download the app</div>
            {/* App links */}
            <div tw="mb-10 lg:(mb-9)">
              {globalData.iosDownloadLink && (
                <a
                  href={globalData.iosDownloadLink}
                  target="_blank"
                  rel="noreferrer"
                  tw="inline-block mr-2.5 w-[123px]"
                >
                  <img src={appStore} />
                </a>
              )}
              {globalData.androidDownloadLink && (
                <a
                  href={globalData.androidDownloadLink}
                  target="_blank"
                  rel="noreferrer"
                  tw="inline-block w-[123px]"
                >
                  <img src={googlePlay} />
                </a>
              )}
            </div>
            <div css={[CopyStyle, tw`mb-2.5 lg:(mb-4)`]}>
              {/* or text me the app */}
            </div>
            {/* <form onSubmit={handleSubmit(onSubmit)}>
              <div
                css={[
                  CopyStyle,
                  tw`flex border border-black w-full mb-7 lg:(mb-5)`,
                ]}
              >
                <input
                  tw="flex-1 pl-6 pr-4 lg:(px-5 py-6)"
                  id="phone-input"
                  name="Phone Number"
                  placeholder="Phone Number"
                  {...register('phone', {
                    required: true,
                    pattern: /^[0-9]{10}$/,
                  })}
                />
                <input
                  className="download-btn"
                  tw="px-7 py-6 bg-[#FF4D00] text-white lg:(w-[174px] cursor-pointer)"
                  type="submit"
                  value="Send"
                />
              </div>
              <div css={[CopyStyle, tw`mb-2`]}>
                By clicking the Button you confirming that you agree with our
                following Terms and Conditions
              </div>
              {errors.phone && (
                <span css={[CopyStyle, tw`text-red-500`]}>
                  Please enter a 10-digit phone number
                </span>
              )}
              {!errors.phone && formSuccess && (
                <span css={[CopyStyle, tw`text-green-500`]}>Text sent!</span>
              )}
            </form> */}
          </div>
        </div>
      </section>

      {/* Features of our app */}
      <Features />

      {/* Testimonials */}
      {pageData.testimonialsTestimonials && (
        <section tw="py-7 lg:(pt-16) overflow-hidden bg-[#F1FAFD]">
          <div tw="mx-7 lg:(max-w-screen-lg mx-auto relative)">
            <h2 css={[HeaderStyle, tw`mb-9`]}>
              {pageData.testimonialsHeadline}
            </h2>
            <Testimonials testimonials={pageData.testimonialsTestimonials} />
          </div>
        </section>
      )}

      {/* FAQ */}
      {pageData.faqQuestions && (
        <FrequentlyAskedQuestions
          headline={pageData.faqHeadline}
          subhead={pageData.faqSubhead}
          questions={pageData.faqQuestions}
          image={pageData.faqImage}
          hospitalityRoute={true}
        />
      )}

      {/* CTA */}
      <CallToAction
        header={pageData.ctaHeadline}
        copy={pageData.ctaSubhead.ctaSubhead}
        ctas={[
          { copy: 'Start Earning', destination: '/hospitality/start-earning' },
          { copy: 'Find Talent', destination: '/hospitality/find-talent' },
        ]}
        image={pageData.ctaImage}
      />
    </Layout>
  )
}

export default App

export const pageQuery = graphql`
  {
    contentfulAppPage(
      pageId: { 
        eq: "App Page"
      }, 
      spaceId: {
        eq: "ufz0zh9i8v45"
      }
    ) {
      heroImage {
        title
        gatsbyImageData(
          layout: CONSTRAINED
          placeholder: NONE
          width: 1024
          quality: 80
        )
      }
      heroHeadline
      testimonialsHeadline
      testimonialsTestimonials {
        copy {
          copy
        }
        name
        initials
        role
      }
      faqImage {
        filename
        gatsbyImageData(
          layout: CONSTRAINED
          placeholder: NONE
          width: 1024
          quality: 80
        )
      }
      faqHeadline
      faqSubhead
      faqQuestions {
        question
        answer {
          raw
        }
      }
      ctaHeadline
      ctaSubhead {
        ctaSubhead
      }
      ctaImage {
        filename
        gatsbyImageData(
          layout: CONSTRAINED
          placeholder: NONE
          width: 1024
          quality: 80
        )
      }
      metadataTitle
      metadataDescription
      metadataImage {
        file {
          url
        }
      }
    }

    contentfulGlobalSettings(pageId: { eq: "Globals" }) {
      iosDownloadLink
      androidDownloadLink
    }
  }
`
